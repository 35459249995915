var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('v-card',{staticClass:"elevation-1",attrs:{"outlined":""}},[_c('v-card-title',[_c('PageTitle',{attrs:{"icon":"mdi-candle","text":"The Attic"}})],1),_c('div',{staticClass:"ma-3"},[_c('h2',[_vm._v("Report")]),_c('v-data-table',{attrs:{"caption":"Report","disable-pagination":"","headers":[
        {text: 'Key', value: 'key'},
        {text: 'Value', value: 'value'}
      ],"hide-default-footer":"","items":_vm.coursesReport}})],1),_c('div',{staticClass:"ma-3 pt-3"},[_c('h2',[_vm._v("Admin Users")]),_c('v-data-table',{attrs:{"caption":"Admin Users","disable-pagination":"","headers":[
        {text: 'Name', value: 'name'},
        {text: 'Email', value: 'email'},
        {text: 'UID', value: 'uid'}
      ],"hide-default-footer":"","items":_vm.adminUsers}})],1),_c('div',{staticClass:"ma-3"},[_c('Configs')],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }